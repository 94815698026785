import React from 'react'
import { Field, Formik } from 'formik'
import * as Yup from 'yup'
import { CustomInput, PrimaryButton } from 'sqrl-design-system'

const ContactUsSchema = Yup.object().shape({
  name: Yup.string()
    .required('Full Name is required')
    .min(5, 'Please check name length'),
  email: Yup.string()
    .required('Email Address is required is required')
    .email('Email Address is invalid'),
  message: Yup.string()
    .required('A message is required')
    .min(20, 'Message should be atleast 20 characters long')
})

const RenderCustomInput = ({ inputName, ...rest }) => (
  <CustomInput name={inputName} {...rest} />
)

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default passedProps => (
  <div>
    <Formik
      initialValues={{}}
      validationSchema={ContactUsSchema}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        console.log(values)
        fetch('/', {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: encode({
            'form-name': 'contact-us',
            ...values
          })
        })
          .then(() => {
            passedProps.onFormResult('success')
            setSubmitting(false)
            resetForm()
          })
          .catch(error => {
            passedProps.onFormResult('error')
          })
      }}
      render={props => (
        <form
          onSubmit={props.handleSubmit}
          onReset={props.handleReset}
          className="form-contact-us"
          name="contact-us"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
        >
          <input type="hidden" name="form-name" value="contact-us" />

          <Field
            className={
              props.touched.name && props.errors.name ? 'is-invalid' : ''
            }
            type="text"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.name}
            id="name"
            name="name"
            inputName="name"
            label="Full Name"
            component={RenderCustomInput}
          />

          <Field
            className={
              props.touched.email && props.errors.email ? 'is-invalid' : ''
            }
            type="email"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.email}
            id="email"
            name="email"
            inputName="email"
            label="Email Address"
            component={RenderCustomInput}
          />

          <Field
            className={
              props.touched.message && props.errors.message ? 'is-invalid' : ''
            }
            type="text"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.message || ''}
            id="message"
            name="message"
            inputName="message"
            placeholder="Type your message here"
            component="textarea"
          />

          <PrimaryButton
            disabled={!props.isValid}
            className="full-width"
            type="submit"
          >
            Send
          </PrimaryButton>
        </form>
      )}
    />
  </div>
)
