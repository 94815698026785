import React, { Component } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Layout from '../layout'

import ContactUs from '../components/ContactUs'
import SEO from '../components/SEO'

class ContactUsPage extends Component {
  render() {
    return (
      <Layout>
        <SEO title="Contact Us" />
        <Header />
        <div style={{ background: 'white', paddingTop: 112 }}>
          <ContactUs />
        </div>
        <Footer style={{ background: 'white' }} />
      </Layout>
    )
  }
}

export default ContactUsPage
