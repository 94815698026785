import React, { Component } from 'react'
import PropTypes from 'prop-types'
import * as typeformEmbed from '@typeform/embed'

class Survey extends Component {
  static defaultProps = {
    mode: 'popup'
  }

  componentDidMount() {
    const { mode, url, onSuveryEnd } = this.props

    const survey = typeformEmbed.makePopup(url, {
      mode,
      autoClose: 2,
      onSubmit: () => {
        if (onSuveryEnd) onSuveryEnd()
      }
    })

    survey.open()
  }

  render() {
    return <></>
  }
}

Survey.propTypes = {
  url: PropTypes.string.isRequired,
  mode: PropTypes.string,
  onSuveryEnd: PropTypes.func
}

export default Survey
