import React, { Component } from 'react'
import { SecondaryButton, InPlaceNotification } from 'sqrl-design-system'

const siteConfig = require('../../../data/SiteConfig')

import ContactUsForm from '../forms/contactUs'
import Survey from '../Survey'

class ContactUs extends Component {
  notificationRef = React.createRef()
  state = {
    showSurvey: false
  }

  cleanSurvey() {
    this.setState({
      showSurvey: false
    })
  }

  _onSubmit(result) {
    let message
    switch (result) {
      case 'success':
        message = 'We received your message and we’ll be in touch very soon!'
        break
      case 'error':
      default:
        message = 'Psst...something went wrong. Please try again.'
        break
    }

    this._showNotification(message, result)
  }

  _showNotification = (message, type) => {
    this.notificationRef.current.showNotification(message, type)
  }

  render() {
    return (
      <>
        <InPlaceNotification ref={this.notificationRef} />
        <div className="contact-wrapper">
          <div className="form-section">
            <h1 className="caps-on">contact us</h1>
            <h2>We’d love to hear from you.</h2>
            <ContactUsForm onFormResult={this._onSubmit.bind(this)} />
          </div>
          <div className="survey-box">
            <div className="survey-container">
              <h2>Wanna share feedback?</h2>
              <p>
                We'd love to hear about your experience with SQRL. Your feedback
                will help us improve the experience.
              </p>
              <SecondaryButton
                onClick={() => this.setState({ showSurvey: true })}
              >
                Fill out survey
              </SecondaryButton>
            </div>
          </div>
        </div>
        {this.state.showSurvey ? (
          <Survey
            url={siteConfig.typeformSurveyURL}
            onSuveryEnd={this.cleanSurvey}
          />
        ) : null}
      </>
    )
  }
}

export default ContactUs
